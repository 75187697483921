/**
 * At the moment this is the preferred card detail for the ZWAR project.
 * Center is Letovice, Czech Republic.
 * TODO: Make it configurable.
 */

export const INITIAL_MAP_CENTER = [49.546944, 16.573333];
export const INITIAL_MAP_ZOOM = 4;

export const MARKER_COLOR_MULTIPLE_TYPES = 'black';
export const MARKER_COLOR_SEGMENT_TYPE = '#1f91d6';
